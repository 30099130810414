<template>
    <div>
        <b-form-group label="Upravnik">
            <validation-provider #default="{ errors }" name="upravnik" rules="required">
                <Select v-model="object.managers" :options="managersList" text="Upravnik"/>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>
        <b-form-group v-if="object.managers !== null" >
            <b-form-checkbox v-if="object.managers" switch v-model="units_checkbox">Izberi objekte, ki bojo prejeli sporočilo</b-form-checkbox>
        </b-form-group>
        <b-form-group v-if="object.limitToBuildings && object.managers" label="Objekti">
            <validation-provider #default="{ errors }" name="objekti" rules="required">
                <Select v-if="object.managers === 'habit'" v-model="object.buildings" :options-multiple="buildingsListHabit" text="Objekti" type="multiple"/>
                <Select v-else-if="object.managers === 'staninvest'" v-model="object.buildings" :options-multiple="buildingsListStaninvest" text="Objekti" type="multiple"/>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>
        <b-form-group class="mt-5">
            <b-form-checkbox switch v-model="object.active">Aktivno</b-form-checkbox>
        </b-form-group>
        <b-form-group label="Naslov">
            <validation-provider #default="{ errors }" name="naslov" rules="required|min:5|max:120">
                <b-form-input v-model="object.title" placeholder="Naslov"/>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>
        <b-form-group label="Opis">
            <validation-provider #default="{ errors }" name="opis" rules="required|min:5|max:15000">
                <TextAreaEditor v-model="object.content" />
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>
        <b-row class="my-2">
            <b-col>
                <p>Datoteke</p>
                <p>Izbrane datoteke: <b>{{ selectedFileNames }}</b></p>
                <!-- @change="fileAdded"  -->
                <b-form-file multiple v-model="files" placeholder="Izberite datoteko ali jo spustite sem..." :file-name-formatter="formatNames" drop-placeholder="Spustite datoteko sem..." accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {BRow, BCol, BFormFile, BFormGroup, BFormInput, BFormCheckbox} from 'bootstrap-vue'
    import { ValidationProvider } from 'vee-validate'
    import { required } from '@validations'
    import TextAreaEditor from '../Components/TextAreaEditor'
    import Select from '@/views/Components/Select.vue'
    export default {
        components: {
            BRow,
            BCol,
            BFormFile,
            BFormGroup,
            BFormInput,
            ValidationProvider,
            TextAreaEditor,
            Select,
            BFormCheckbox
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                default: null
            }
        },
        data() {
            return {
                files: [],
                required,
                managersList: this.$store.getters['user/managers'],
                selectedFileNames: '',
                buildings:[],
                buildingsListHabit: [],
                buildingsListStaninvest: [],
                units_checkbox: false
            }
        },
        watch:{
            files(val) {
                this.object.files = []
                const files = val

                for (const file of files) {
                    if (file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                        this.object.files.push(file)
                    }
                }

                this.selectedFileNames = this.object.files.map((a) => a.name).join(', ')
            },
            units_checkbox(val) {
                this.object.limitToBuildings = val
            }
        },
        mounted() {
            this.getBuildings()
        },
        methods: {
            formatNames() {
                return this.selectedFileNames
            },
            getBuildings() {
                const thisIns = this

                this.$http.get('/api/management/v1/objekti/habit')
                    .then(res => {
                        thisIns.buildingsListHabit = res.data.map(item => item.naziv)
                    }).catch(function(error) {
                        thisIns.$printError(error.message)
                    })

                this.$http.get('/api/management/v1/objekti/staninvest')
                    .then(res => {
                        thisIns.buildingsListStaninvest = res.data.map(item => item.naziv)
                    }).catch(function(error) {
                        thisIns.$printError(error.message)
                    })
            }
        },
        computed: {
            object: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('change', value)
                }
            }
        }
    }
</script>

<style scoped>

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
