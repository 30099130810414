var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{attrs:{"label":"Upravnik"}},[_c('validation-provider',{attrs:{"name":"upravnik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"options":_vm.managersList,"text":"Upravnik"},model:{value:(_vm.object.managers),callback:function ($$v) {_vm.$set(_vm.object, "managers", $$v)},expression:"object.managers"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.object.managers !== null)?_c('b-form-group',[(_vm.object.managers)?_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.units_checkbox),callback:function ($$v) {_vm.units_checkbox=$$v},expression:"units_checkbox"}},[_vm._v("Izberi objekte, ki bojo prejeli sporočilo")]):_vm._e()],1):_vm._e(),(_vm.object.limitToBuildings && _vm.object.managers)?_c('b-form-group',{attrs:{"label":"Objekti"}},[_c('validation-provider',{attrs:{"name":"objekti","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.object.managers === 'habit')?_c('Select',{attrs:{"options-multiple":_vm.buildingsListHabit,"text":"Objekti","type":"multiple"},model:{value:(_vm.object.buildings),callback:function ($$v) {_vm.$set(_vm.object, "buildings", $$v)},expression:"object.buildings"}}):(_vm.object.managers === 'staninvest')?_c('Select',{attrs:{"options-multiple":_vm.buildingsListStaninvest,"text":"Objekti","type":"multiple"},model:{value:(_vm.object.buildings),callback:function ($$v) {_vm.$set(_vm.object, "buildings", $$v)},expression:"object.buildings"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3266185013)})],1):_vm._e(),_c('b-form-group',{staticClass:"mt-5"},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.object.active),callback:function ($$v) {_vm.$set(_vm.object, "active", $$v)},expression:"object.active"}},[_vm._v("Aktivno")])],1),_c('b-form-group',{attrs:{"label":"Naslov"}},[_c('validation-provider',{attrs:{"name":"naslov","rules":"required|min:5|max:120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Naslov"},model:{value:(_vm.object.title),callback:function ($$v) {_vm.$set(_vm.object, "title", $$v)},expression:"object.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Opis"}},[_c('validation-provider',{attrs:{"name":"opis","rules":"required|min:5|max:15000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextAreaEditor',{model:{value:(_vm.object.content),callback:function ($$v) {_vm.$set(_vm.object, "content", $$v)},expression:"object.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',{staticClass:"my-2"},[_c('b-col',[_c('p',[_vm._v("Datoteke")]),_c('p',[_vm._v("Izbrane datoteke: "),_c('b',[_vm._v(_vm._s(_vm.selectedFileNames))])]),_c('b-form-file',{attrs:{"multiple":"","placeholder":"Izberite datoteko ali jo spustite sem...","file-name-formatter":_vm.formatNames,"drop-placeholder":"Spustite datoteko sem...","accept":"application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }