<template>
    <b-overlay :show="showLoader">
        <b-card>
            <h2 style="font-weight: bold;">Izpolnite podatke</h2>
            <validation-observer ref="validate">
                <b-form class="form mt-1">
                    <BulletinBoard v-model="object"/>
                    <b-form-group class="text-right">
                        <b-button variant="secondary" @click.prevent="validationForm">ODDAJ OGLAS</b-button>
                    </b-form-group>
                </b-form>
            </validation-observer>
        </b-card>
    </b-overlay>
</template>

<script>
    import { ValidationObserver } from 'vee-validate'
    import BulletinBoard from '@/views/Components/BulletinBoard'
    import {BButton, BCard, BForm, BFormGroup, BOverlay} from 'bootstrap-vue'

    export default {
        components: {
            BulletinBoard,
            ValidationObserver,
            BOverlay,
            BCard,
            BForm,
            BFormGroup,
            BButton
        },
        data() {
            return {
                object: {
                    title:'',
                    content:'',
                    documents: [],
                    managers: [],
                    active: true,
                    files: [],
                    buildings: [],
                    buildingsSifre: [],
                    limitToBuildings: false
                },
                showLoader: false,
                valid: true
            }
        },
        methods: {
            async validationForm() {
                this.$refs.validate.validate().then(async(success) => {
                    if (success) {
                        await this.addBulletin()
                    }
                })

            },
            async addBulletin() {
                try {
                    this.showLoader = true

                    if (this.object.files.length > 0) {
                        this.object.documents = await this.uploadDocuments()
                    }

                    this.object.buildingsSifre = this.object.buildings.map(function(v) { return v.split(' | ') })
                    if (this.object.buildingsSifre !== null) {
                        this.object.buildingsSifre = this.object.buildingsSifre.map(item => item[item.length - 1])
                    }

                    const payload = {
                        'title':this.object.title,
                        'content':this.object.content,
                        'documents': this.object.documents,
                        'managers': this.object.managers,
                        'active': this.object.active,
                        'files': this.object.files,
                        'buildings': this.object.buildingsSifre,
                        'limitToBuildings': this.object.limitToBuildings
                    }

                    await this.$http.put('/api/management/v1/bulletin', payload)
                    this.$printSuccess('Obvestilo je dodano')
                    await this.$router.push({name: 'bulletin-board'})

                } catch (error) {
                    this.$printError(error)
                } finally {
                    this.showLoader = false
                }
            },

            async uploadDocuments() {
                try {
                    const documents = []

                    const promises = []

                    for (const file of this.object.files) {
                        const formData = new FormData()
                        formData.append('file', file)

                        const loadPromise = await this.$http.post('/api/management/v1/file/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        promises.push(loadPromise)
                    }
                    const results = await Promise.allSettled(promises)
                    for (const res of results) {
                        if (res.status === 'fulfilled') {
                            documents.push(res.value.data)
                        } else {
                            this.$printError('Napaka pri nalaganju dokumentov')
                        }
                    }
                    return documents
                } catch (error) {
                    this.$printError(error.response.data)
                }
            }
        }
    }
</script>
<style>
.ql-editor{
  min-height:250px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
